//import withRoot from "../utils/withRoot";
import React, {useState, useEffect} from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import SEO from "../components/SEO";
import Card from "../components/Card";
 
import Button from "@material-ui/core/Button";
 
import Avatar from "@material-ui/core/Avatar";
import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { makeStyles, Theme, createStyles, CircularProgress, Container, Grid, Typography, CardMedia } from "@material-ui/core";
import {auth, useAuth,  firestore , firebase, useFirestoreQuery}  from 'gatsby-theme-firebase';
import CarouselPost from "../components/CarouselPost";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: "bold",
      textTransform: "none"

    },
 
    contentBox: {
      maxWidth: "calc(1136px - 60px)",
      width: "calc(100% - 60px)",
 
    },
    headerSpacer2: {
      flex: 1,
      display: 'fixed',
      marginLeft: '50%',
      height: '350px',
    },
    headerSpacer3: {
      flex: 1,
      display: 'fixed',
      marginTop: '35%',
 
    },
    containerUi: {
    
      marginLeft: -15,

    },
  }),
);
 


const  Home = (props) => {

  const classes = useStyles();
  var [postsData, setPostsData] = useState<firebase.firestore.DocumentData[]>([]);
//  const [sortPostsData, setSortPostsData] = useState([]);
  var [isLoading,setIsLoading] = useState(false);
  var [error, setError] = React.useState<Error | null>(null);

  const useFirestoreQuery3 = <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    
    const unsubscribe = query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
        setIsLoading(false);
        setPostsData(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
      
      },
      (err: Error) => {
        console.log("useFirestoreQuery3: " + err);
        console.error(err);
        setError(err);
      },()=>{
      
      }
    );  
    return () => unsubscribe();
  }

  useEffect(() => {
    console.log("wheretext and whervalue change");
       setIsLoading(true);
       useFirestoreQuery3(firestore.collection('posts').where( "postType","==", "post").where("sharedType","==","public").orderBy("countLike","desc").limit(5) );
       
  },[]);

 
 
  return (
  
     
       <Container>
       <Grid container direction="row" justify="center" alignItems="center">  
             
             <Grid item >     
          
              
                 <Typography variant="h5"> -- you need to login to see the content, thanks -- </Typography> 
              
             </Grid>
             
           </Grid>
        
        <Grid container direction="row" justify="center" alignItems="center">  
             
             <Grid item >     
          
                 <Typography variant="caption">natnat.conde@gmail.com</Typography> 
              
             </Grid>
             
           </Grid>
      </Container>
       
    
  );
  };

 
export default Home;
 
